import {RoutingModule} from './app-routing.module';
import {NgModule} from '@angular/core';
import {
  ComponentModule,
  ECaseConfigService,
  ECaseDateFormatterPipe,
  EcaseHttpService,
  ECaseNavigationService,
  ECaseSnackBarService,
  ECaseSplashScreenService,
  ECaseTranslationLoaderService,
  LanguageService,
  LoginAuthenticationService,
  MaterialModule,
  MatProgressBarService,
  ParametersService,
  PendingChangesGuard,
  ProjectService,
  RefreshContentTabsService,
  SectionBreadCrumbService,
  SharedModule,
  TitleService
} from 'synto-common';
import {ECaseNavigationModule} from 'synto-common/navigation-common';
import {ECaseResetPasswordComponent} from './login/authentication/reset-password/reset-password.component';
import {ECaseForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {ActivatorComponent} from './login/activator/activator.component';
import {BrowserSupportComponent} from './browser-support/browser-support.component';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';
import {VersionCheckService} from './version-check.service';
import {CookiesPolicyDialogComponent} from './login/cookies-policy-dialog/cookies-policy-dialog.component';
import {LoginComponent} from './login/login.component';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {ShibbolethLoginComponent} from './login/shibboleth-login/shibboleth-login.component';
import {
  PublicDocumentationDialogComponent
} from './login/public-documentation-dialog/public-documentation-dialog.component';
import {AdfsSaml2LoginComponent} from './login/adfs-saml2-login/adfs-saml2-login.component';
import {PrivacyComponent} from './privacy/privacy.component';
import {
  TwoFactorAuthenticationComponent
} from './login/authentication/two-factor-authentication/two-factor-authentication.component';
import {ECaseRegisterComponent} from './login/authentication/register/register.component';
import {NgxCaptchaModule} from 'ngx-captcha';
import {HttpClientModule} from '@angular/common/http';
import {GeneralDirectoryComponent} from './general-directory/general-directory.component';
import {ECaseExternalRefereeComponent} from "./external-referee/external-referee.component";
import {SyntoCoreModule} from "synto-common/synto-core";
import {ECaseDashboardModule} from "./dashboard/dashboard.module";


@NgModule({
  declarations: [AppComponent,
    CookiesPolicyDialogComponent,
    LoginComponent,
    ShibbolethLoginComponent,
    ActivatorComponent,
    ECaseResetPasswordComponent,
    ECaseForgotPasswordComponent,
    ECaseRegisterComponent,
    TwoFactorAuthenticationComponent,
    AdfsSaml2LoginComponent,
    PrivacyComponent,
    BrowserSupportComponent,
    PublicDocumentationDialogComponent,
    GeneralDirectoryComponent,
    ECaseExternalRefereeComponent
  ],
  imports: [
    NoopAnimationsModule,
    ECaseNavigationModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    MaterialModule,
    TranslateModule.forRoot(),
    RoutingModule,
    NgxCaptchaModule,
    ComponentModule
    //  ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately' }),
  ],
  providers: [
    ECaseSplashScreenService, MatProgressBarService, LoginAuthenticationService, ECaseSnackBarService, TranslateService,
    LanguageService, RefreshContentTabsService, ECaseConfigService, ECaseNavigationService, ECaseDateFormatterPipe,
    ProjectService, ECaseTranslationLoaderService, EcaseHttpService, SectionBreadCrumbService, ParametersService,
    TitleService, VersionCheckService, PendingChangesGuard
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {
}
